.ai-image-generator{
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  margin-top: 50px;
  gap: 30px;
}
.header{
  font-size: 50px;
  font-weight: bold;
  color: #ffd000;
}
.header span{
  color: black;
}
.img-loading{
  display: flex;
  flex-direction: column;
}
.img-loading img{
  width: 550px;
}
.search-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px; /* Rounded corners */
  background-color: #f0f0f0; /* Light gray background */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: all 0.3s ease; /* Smooth transition */
  width: 600px; /* Increased width of the search box */
}

.search-box:hover {
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); /* Shadow on hover */
  background-color: #e0e0e0; /* Slightly darker on hover */
}

.search-input {
  width: 100%; /* Full width to fill the search box */
  padding: 12px 20px; /* Padding inside the input */
  border: none; /* No border */
  border-radius: 30px; /* Match the box's rounded corners */
  font-size: 16px; /* Font size */
  outline: none; /* Remove outline */
  transition: all 0.3s ease; /* Smooth transition */
}

.search-input::placeholder {
  color: #aaa; /* Placeholder color */
}

.search-input:focus {
  border: 2px solid black; /* Border color on focus */
  box-shadow: 0 0 5px black; /* Shadow on focus */
}

.generate-btn {
  display: inline-block; /* Align with other inline elements */
  padding: 12px 24px; /* Padding for top/bottom and left/right */
  background-color: #6200ea; /* Button background color */
  color: #ffffff; /* Text color */
  font-size: 16px; /* Font size */
  font-weight: bold; /* Bold text */
  border: none; /* No border */
  border-radius: 30px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  text-align: center; /* Center text */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  transition: all 0.3s ease; /* Smooth transition */
}
.generate-btn:hover {
  background-color: #3700b3; /* Darker shade on hover */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3); /* Stronger shadow on hover */
}
.generate-btn:focus {
  outline: none; /* Remove outline */
  box-shadow: 0 0 5px rgba(98, 0, 234, 0.5); /* Focus shadow */
}
.loading-bar{
  width: 0px;
  height: 8px;
  background: #6200ea;
}
.loading-bar-full{
  width: 600px;
  height: 8px;
  background: #6200ea;
  transition: 15s;
}
.display-none{
  display: none;
}
.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: transparent;
  border: none;
  color: #000;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.back-button:hover {
  color: #555;
}
